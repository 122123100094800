import React from "react";
import {
  Email,
  Facebook,
  Instagram,
  Pin,
  Viber,
  WhatsApp,
} from "../Icons/Icons";

const Footer = () => {
  return (
    <div
      id="Kontakt"
      className="text-light relative bg-primary pt-72 -mt-60 md:-mt-48 xl:-mt-36 pb-8 overflow-hidden"
    >
      <div className="container flex flex-col items-center gap-24 md:gap-36">
        <div className="flex flex-col items-center gap-4">
          <h3 className="font-bold text-4xl md:text-5xl text-center">
            Poručite Vozilo
          </h3>
          <div className="text-xl flex flex-wrap gap-4 text-center justify-center max-w-max items-center">
            <a href="tel:0613163000">061 3163000</a>|
            <a href="tel:0643163000">064 3163000</a>|
            <a href="tel:0693163000">069 3163000</a>
          </div>
        </div>
        <div className="flex flex-col items-center gap-4">
          <div className="flex items-center justify-center flex-wrap gap-4 h-8 md:h-6">
            <a
              href="https://www.instagram.com/supertaxi.nis/"
              target="_blank"
              rel="noreferrer"
              className="h-full"
            >
              <Instagram />
            </a>
            <a
              href="https://www.facebook.com/share/G4hopzPrHty7K7Bw/?mibextid=qi2Omg"
              target="_blank"
              rel="noreferrer"
              className="h-full"
            >
              <Facebook />
            </a>
            <a
              href="https://wa.me/381643163000"
              target="_blank"
              rel="noreferrer"
              className="h-full"
            >
              <WhatsApp />
            </a>
            <a
              href="viber://chat?number=381643163000"
              target="_blank"
              rel="noreferrer"
              className="h-full"
            >
              <Viber />
            </a>
            <a
              href="mailto:supertaksinis@gmail.com"
              target="_blank"
              rel="noreferrer"
              className="h-full"
            >
              <Email />
            </a>
          </div>
          <p className="font-bold text-center">
            © Copyright 2024 Super Taxi Niš | Sva prava zadržana
          </p>
          <p className="text-center">
            Designed by <span className="font-bold">MarkoUIUX</span>. Developed
            by <span className="font-bold">Lazar</span>.
          </p>
        </div>
      </div>
      <div className="absolute rotate-[15deg] top-[55%] -left-[5%] pointer-events-none">
        <Pin width={"344"} color={"var(--transparent-purple)"} />
      </div>
      <div className="absolute -rotate-45 top-[35%] -right-[12%] hidden md:block pointer-events-none">
        <Pin width={"510"} color={"var(--transparent-purple)"} />
      </div>
    </div>
  );
};

export default Footer;
