const pricesData = [
  {
    section: "gradovi",
    relation: "Prokuplje",
    price: "2.000 RSD",
  },
  {
    section: "gradovi",
    relation: "Aleksinac",
    price: "2.000 RSD",
  },
  {
    section: "gradovi",
    relation: "Beograd",
    price: "15.000 RSD",
  },
  {
    section: "gradovi",
    relation: "Beograd Aerodrom",
    price: "16.000 RSD",
  },
  {
    section: "gradovi",
    relation: "Bela Palanka (auto-put)",
    price: "2.700 RSD",
  },
  {
    section: "gradovi",
    relation: "Bela Palanka (stari put)",
    price: "2.600 RSD",
  },
  {
    section: "gradovi",
    relation: "Batočina",
    price: "8.000 RSD",
  },
  {
    section: "gradovi",
    relation: "Blace",
    price: "3.500 RSD",
  },
  {
    section: "gradovi",
    relation: "Bojnik",
    price: "2.700 RSD",
  },
  {
    section: "gradovi",
    relation: "Bor",
    price: "8.000 RSD",
  },
  {
    section: "gradovi",
    relation: "Brus",
    price: "5.000 RSD",
  },
  {
    section: "gradovi",
    relation: "Čačak (preko Kraljeva)",
    price: "11.500 RSD",
  },
  {
    section: "gradovi",
    relation: "Ćuprija",
    price: "5.500 RSD",
  },
  {
    section: "gradovi",
    relation: "Dimitrovgrad",
    price: "6.000 RSD",
  },
  {
    section: "gradovi",
    relation: "Jagodina",
    price: "6.500 RSD",
  },
  {
    section: "gradovi",
    relation: "Kikinda",
    price: "25.000 RSD",
  },
  {
    section: "gradovi",
    relation: "Knjaževac",
    price: "3.500 RSD",
  },
  {
    section: "gradovi",
    relation: "Kragujevac",
    price: "10.000 RSD",
  },
  {
    section: "gradovi",
    relation: "Kraljevo (preko Pojata)",
    price: "9.000 RSD",
  },
  {
    section: "gradovi",
    relation: "Kraljevo (preko Djunisa)",
    price: "8.000 RSD",
  },
  {
    section: "gradovi",
    relation: "Kruševac",
    price: "4.500 RSD",
  },
  {
    section: "gradovi",
    relation: "Kuršumlija",
    price: "3.800 RSD",
  },
  {
    section: "gradovi",
    relation: "Lebane",
    price: "4.000 RSD",
  },
  {
    section: "gradovi",
    relation: "Leskovac (auto-put)",
    price: "3.000 RSD",
  },
  {
    section: "gradovi",
    relation: "Leskovac (stari put)",
    price: "2.700 RSD",
  },
  {
    section: "gradovi",
    relation: "Loznica",
    price: "22.000 RSD",
  },
  {
    section: "gradovi",
    relation: "Medvedja (preko Bojnika)",
    price: "5.000 RSD",
  },
  {
    section: "gradovi",
    relation: "Medvedja (preko Leskovca)",
    price: "5.500 RSD",
  },
  {
    section: "gradovi",
    relation: "Negotin",
    price: "9.000 RSD",
  },
  {
    section: "gradovi",
    relation: "Novi Pazar",
    price: "12.000 RSD",
  },
  {
    section: "gradovi",
    relation: "Novi Sad",
    price: "22.000 RSD",
  },
  {
    section: "gradovi",
    relation: "Paraćin",
    price: "5.000 RSD",
  },
  {
    section: "gradovi",
    relation: "Pirot",
    price: "4.300 RSD",
  },
  {
    section: "gradovi",
    relation: "Požarevac",
    price: "12.000 RSD",
  },
  {
    section: "gradovi",
    relation: "Raška",
    price: "10.000 RSD",
  },
  {
    section: "gradovi",
    relation: "Smederevo",
    price: "12.000 RSD",
  },
  {
    section: "gradovi",
    relation: "Subotica",
    price: "30.000 RSD",
  },
  {
    section: "gradovi",
    relation: "Surdulica",
    price: "6.000 RSD",
  },
  {
    section: "gradovi",
    relation: "Svrljig",
    price: "1.800 RSD",
  },
  {
    section: "gradovi",
    relation: "Tutin",
    price: "15.000 RSD",
  },
  {
    section: "gradovi",
    relation: "Užice",
    price: "15.000 RSD",
  },
  {
    section: "gradovi",
    relation: "Velika Plana",
    price: "9.000 RSD",
  },
  {
    section: "gradovi",
    relation: "Vladičin Han",
    price: "5.500 RSD",
  },
  {
    section: "gradovi",
    relation: "Vlasotince",
    price: "3.500 RSD",
  },
  {
    section: "gradovi",
    relation: "Vranje",
    price: "7.000 RSD",
  },
  {
    section: "gradovi",
    relation: "Zaječar",
    price: "6.000 RSD",
  },
  {
    section: "gradovi",
    relation: "Žitoradja",
    price: "Po taksimetru",
  },
  {
    section: "gradovi",
    relation: "Zrenjanin",
    price: "20.000 RSD",
  },
  {
    section: "van-granice",
    relation: "Sofija",
    price: "130 EUR",
  },
  {
    section: "van-granice",
    relation: "Sofija (aerodrom)",
    price: "150 EUR",
  },
  {
    section: "van-granice",
    relation: "Skoplje",
    price: "200 EUR",
  },

  {
    section: "van-granice",
    relation: "Podgorica",
    price: "450 EUR",
  },
  {
    section: "van-granice",
    relation: "Sarajevo",
    price: "500 EUR",
  },
  {
    section: "van-granice",
    relation: "Budimpešta",
    price: "700 EUR",
  },

  {
    section: "van-granice",
    relation: "Zagreb",
    price: "700 EUR",
  },
  {
    section: "banje",
    relation: "Sokobanja",
    price: "3.700 RSD",
  },
  {
    section: "banje",
    relation: "Kuršumlijska Banja",
    price: "4.500 RSD",
  },
  {
    section: "banje",
    relation: "Prolom Banja",
    price: "5.000 RSD",
  },
  {
    section: "banje",
    relation: "Jošanička Banja",
    price: "7.500 RSD",
  },
  {
    section: "banje",
    relation: "Banja Koviljača",
    price: "25.000 RSD",
  },

  {
    section: "banje",
    relation: "Lukovska Banja",
    price: "6.000 RSD",
  },

  {
    section: "banje",
    relation: "Ribarska Banja",
    price: "3.000 RSD",
  },
  {
    section: "banje",
    relation: "Sijarinska Banja",
    price: "6.000 RSD",
  },
  {
    section: "banje",
    relation: "Vranjska Banja",
    price: "7.000 RSD",
  },
  {
    section: "banje",
    relation: "Vrnjačka Banja",
    price: "8.000 RSD",
  },
  {
    section: "planine",
    relation: "Babin Zub",
    price: "5.500 RSD",
  },
  {
    section: "planine",
    relation: "Kopaonik",
    price: "8.000 RSD",
  },
  {
    section: "planine",
    relation: "Stara Planina",
    price: "5.000 RSD",
  },
  {
    section: "planine",
    relation: "Tara",
    price: "20.000 RSD",
  },
  {
    section: "planine",
    relation: "Zlatibor",
    price: "18.000 RSD",
  },
];

const sections = ["Gradovi", "Banje", "Planine", "Van Granice"];

export { pricesData, sections };
