import React from "react";
import ZastoNas from "./ZastoNas";
import MyWorld from "./MyWorld";
import About from "./About";
import Prices from "./Prices/Prices";
import Footer from "../Footer/Footer";

const Main = () => {
  return (
    <div className="relative z-50">
      <ZastoNas />
      <About />
      <MyWorld />
      <Prices />
    </div>
  );
};

export default Main;
