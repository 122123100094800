import React from "react";

const Section = ({ data, selected, setSelected }) => {
  return (
    <div
      className={`rounded-full px-6 py-3 bg-accent md:text-xl cursor-pointer select-none hover:bg-primary hover:text-light transition-colors duration-300 ${
        selected === data ? "bg-primary text-light" : ""
      }`}
      onClick={() => setSelected(data)}
    >
      {data}
    </div>
  );
};

export default Section;
