import React, { useState } from "react";
import { Hamburger, Logo } from "./Icons/Icons.jsx";
import AnchorLink from "react-anchor-link-smooth-scroll";

const Navigation = () => {
  const [isShownNav, setIsShownNav] = useState(false);
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div className="container sticky top-4 z-[100] 2xl:max-w-[calc(100vw-3rem)]">
      <div className="flex justify-between items-center   bg-primary p-6 rounded-2xl nav-shadow">
        {/* <img
          src={require("./Images/Logo.png")}
          alt="logo"
          className="z-10 cursor-pointer"
          onClick={() => {
            scrollToTop();
            setIsShownNav(false);
          }}
        /> */}
        <div
          className="z-10 cursor-pointer"
          onClick={() => {
            scrollToTop();
            setIsShownNav(false);
          }}
        >
          <Logo />
        </div>
        <nav
          className={`lg:absolute text-white lg:top-[50%] lg:left-[50%] lg:-translate-x-1/2 lg:-translate-y-1/2 backdrop-blur-2xl bg-primary lg:bg-transparent  fixed inset-0 lg:inset-auto -translate-y-full transition-[transform] duration-300 lg:transition-none ${
            isShownNav ? "translate-y-0" : ""
          }`}
        >
          <ul className="flex gap-8 lg:flex-row flex-col items-center pt-24 lg:pt-0 h-full text-2xl lg:text-base">
            <li
              onClick={() => {
                scrollToTop();
                setIsShownNav(false);
              }}
              className="hover:cursor-pointer"
            >
              Početna
            </li>
            <li
              onClick={() => setIsShownNav(false)}
              className="hover:cursor-pointer"
            >
              <AnchorLink href="#About" offset={100}>
                O nama
              </AnchorLink>
            </li>
            <li
              onClick={() => setIsShownNav(false)}
              className="hover:cursor-pointer"
            >
              <AnchorLink href="#Aplikacija" offset={-60}>
                Aplikacija
              </AnchorLink>
            </li>
            <li
              onClick={() => setIsShownNav(false)}
              className="hover:cursor-pointer"
            >
              <AnchorLink href="#Cenovnik" offset={-160}>
                Cenovnik
              </AnchorLink>
            </li>
            <li
              onClick={() => setIsShownNav(false)}
              className="hover:cursor-pointer"
            >
              <AnchorLink href="#Kontakt">Kontakt</AnchorLink>
            </li>
          </ul>
        </nav>
        <Hamburger isShownNav={isShownNav} setIsShownNav={setIsShownNav} />
      </div>
    </div>
  );
};

export default Navigation;
