import React from "react";
import data from "./ZastoNasData.js";
import { Car, Lightning, Money, Pin } from "../Icons/Icons.jsx";

const ZastoNas = () => {
  return (
    <div
      id="About"
      className="relative bg-primary flex flex-col gap-16 items-center text-light py-20 md:py-26 xl:py-32 mt-12 md:mt-20 xl:mt-28 rounded-5xl z-40 overflow-hidden"
    >
      <h2 className="text-4xl md:text-5xl xl:text-6xl font-bold">
        Zašto Izabrati Nas?
      </h2>
      <div className="grid md:grid-cols-3 gap-8 container container-bp md:px-6">
        {data.map((el) => {
          return (
            <div className="flex flex-col" key={el.text}>
              <div className="w-16 aspect-square rounded-2xl flex justify-center items-center bg-[rgba(247,246,242,0.2)] mb-8">
                {el.icon === "Lightning" ? (
                  <Lightning />
                ) : el.icon === "Car" ? (
                  <Car />
                ) : (
                  <Money />
                )}
              </div>
              <p className="text-2xl font-bold mb-4">{el.header}</p>
              <p className="opacity-75">{el.text}</p>
            </div>
          );
        })}
      </div>
      <div className="absolute -left-[15%] bottom-[55%] rotate-[135deg] w-[304px] sm:w-[510px]">
        <Pin color={"var(--transparent-purple)"} />
      </div>
      <div className="absolute -right-[10%] -bottom-28 sm:top-[35%] -rotate-45 -z-30 w-[243px] sm:w-[390px]">
        <Pin color={"var(--transparent-purple)"} />
      </div>
    </div>
  );
};

export default ZastoNas;
