import React from "react";
import { Pin } from "../Icons/Icons";

const MyWorld = () => {
  return (
    <div
      id="Aplikacija"
      className="relative bg-primary overflow-hidden pb-36 pt-72 -mt-60 md:-mt-48 xl:-mt-36 rounded-5xl text-light z-20"
    >
      <div className="gap-x-40 gap-y-12 container myworld-container grid-rows-2 lg:grid-rows-1">
        <div className="flex flex-col gap-6">
          <h2 className="text-4xl md:text-5xl xl:text-6xl font-black">
            Vozi Se i Zaradi
          </h2>
          <p className="lg:text-xl opacity-75">
            Super Taxi i My World aplikacija Vam nude nesto novo na našim
            prostorima! Prilikom svake vožnje dobijate{" "}
            <span className="font-bold">Cashback</span> i{" "}
            <span className="font-bold">Šoping Poene</span> koje mozete
            iskoristiti u preko 160 partnerskih kompanija u Nišu!
          </p>
          <a
            href="https://www.myworld.com/joinforfree?redirect_uri=https%3a%2f%2flink.myworld.com%2fdynamic%2fYC35ZEZYjQJewm3c6"
            target="_blank"
            rel="noreferrer"
            className="text-primary hover:bg-light transition-colors duration-300 rounded-2xl px-8 py-4 bg-white/50 font-bold w-max"
          >
            Preuzmite MyWorld Aplikaciju
          </a>
        </div>
        <div className="overflow-hiddens rounded-4xls bg-covers relative min-h-10s w-full mx-auto">
          <img
            src={require("../Images/MyWorld.webp")}
            alt="Vehicles"
            className="object-cover object-center max-w-[400px] w-full top-0 absolute left-1/2 -translate-x-1/2 rounded-4xl"
          />
        </div>
      </div>
      <div className="absolute -left-[10%] -bottom-[20%] rotate-[45deg] -z-10 w-[390px]">
        <Pin color={"var(--transparent-purple)"} />
      </div>
    </div>
  );
};

export default MyWorld;
