import React, { useEffect, useState } from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";

const About = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    window.onresize = () => setWindowWidth(window.innerWidth);
  }, []);
  return (
    <div className="bg-light pb-12 md:pb-24 xl:pb-36 pt-72 -mt-60 md:-mt-48 xl:-mt-36 rounded-5xl z-30 relative">
      <div className="grid md:grid-cols-2 gap-8 items-center container">
        <div className="flex flex-col gap-6">
          <h2 className="text-4xl md:text-5xl xl:text-6xl font-black">
            Ko smo mi?
          </h2>
          <p className="opacity-75 lg:text-xl">
            Super Taxi sačinjava grupa pažljivo odabranih profesionalnih vozača
            sa taxi dozvolama i dugogodišnjim iskustvom. Ono što nas spaja jeste
            isti cilj, a to je pružanje najbolje moguće usluge našim
            korisnicima.
          </p>
          <p className="font-bold lg:text-xl">Probajte i uverite se sami!</p>
          {windowWidth > 768 ? (
            <AnchorLink
              href="#Kontakt"
              className="text-primary rounded-2xl px-8 py-4 bg-accent hover:bg-primary hover:text-light transition-colors duration-300 font-bold w-max"
            >
              Vozi se sa nama
            </AnchorLink>
          ) : (
            <a
              href="tel:0643163000"
              className="text-primary rounded-2xl px-8 py-4 bg-accent hover:bg-primary hover:text-light transition-colors duration-300 font-bold w-max"
            >
              Vozi se sa nama
            </a>
          )}
        </div>
        <div className="overflow-hidden rounded-4xl object-cover">
          <img
            src={require("../Images/About-image.webp")}
            alt="Vehicles"
            className="object-cover object-center w-full"
          />
        </div>
      </div>
    </div>
  );
};

export default About;
