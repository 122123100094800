const data = [
  {
    icon: "Lightning",
    header: "Profesionalizam i Ljubaznost",
    text: "Naše udruženje se temelji na moralnim načelima, pružajući vrhunsku uslugu. Svi naši vozači imaju Taxi Dozvole i dugogodišnje iskustvo.",
  },
  {
    icon: "Car",
    header: "Komfor i Udobnost",
    text: "Naša flota uključuje vozila nove generacije - komforna, uvek čista i ispravna, koja pružaju udobnu vožnju, bilo za kratka ili duga putovanja.",
  },
  {
    icon: "Money",
    header: "Pristupačne Cene",
    text: "Pored profesionalizma i komfora, nudimo vam i najpovoljnije cene u gradu. Vaše zadovoljstvo nam je na prvom mestu!",
  },
];

export default data;
