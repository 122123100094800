import React, { useEffect, useState } from "react";
import { pricesData, sections } from "./PricesData";
import Section from "./Section";

const Prices = () => {
  const [selected, setSelected] = useState(sections[0]);
  const [filteredData, setFilteredData] = useState([]);
  const [showFive, setShowFive] = useState(false);

  useEffect(() => {
    setFilteredData(
      pricesData.filter(
        (el) =>
          el.section.toLowerCase().split(" ").join("-") ===
          selected.toLowerCase().split(" ").join("-")
      )
    );
    setShowFive(true);
  }, [selected]);

  return (
    <div
      id="Cenovnik"
      className="pb-12 sm:pb-24 md:pb-36 pt-72 -mt-60 md:-mt-48 xl:-mt-40 bg-light rounded-5xl relative z-10"
    >
      <div className="container">
        <h2 className="font-black text-4xl md:text-5xl lg:text-6xl text-center mb-10 md:mb-12">
          Cenovnik
        </h2>
        <div className="flex items-center justify-center flex-wrap gap-4 mx-auto max-w-max mb-4 md:mb-6">
          {sections.map((el) => (
            <Section
              data={el}
              selected={selected}
              setSelected={setSelected}
              key={el}
            />
          ))}
        </div>
        <div className="flex flex-col gap-4 md:text-xl">
          <div className="bg-primary flex justify-between p-5 md:p-6 text-light rounded-2xl font-bold">
            <p>Destinacije</p>
            <p>Cene</p>
          </div>
          {filteredData.map((el, idx) => {
            return (
              <div
                key={el.price + idx}
                className={`bg-accent flex justify-between p-5 md:p-6 rounded-2xl ${
                  idx > 4 && showFive ? "hidden" : ""
                }`}
              >
                <p>{el.relation}</p>
                <p>{el.price}</p>
              </div>
            );
          })}
          {filteredData.length > 5 ? (
            <div
              className="max-w-max mx-auto font-medium rounded-full px-8 py-3 bg-accent hover:bg-primary hover:text-light transition-colors duration-300 cursor-pointer select-none"
              onClick={() => setShowFive(!showFive)}
            >
              {showFive ? "Prikaži još" : "Prikaži manje"}
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default Prices;
