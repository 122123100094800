import React, { useEffect, useState } from "react";
import { Pin } from "../Icons/Icons";
import AnchorLink from "react-anchor-link-smooth-scroll";

const Header = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    window.onresize = () => setWindowWidth(window.innerWidth);
  }, []);

  return (
    <div className="container 2xl:max-w-[calc(100vw-3rem)]">
      <div
        className="px-4 relative bg-center bg-cover rounded-4xl flex flex-col items-center py-36 mt-10 xl:mt-16 overflow-hidden text-black"
        style={{
          backgroundImage: `url(${require("../Images/Header-image.webp")})`,
        }}
      >
        <h1 className="text-4xl md:text-5xl lg:text-6xl xl:text-7xl font-black mb-6 max-w-[15ch] text-center">
          Sigurno, Povoljno i Profesionalno
        </h1>
        <p className="mb-8 text-center">
          Profesionalizam, ljubaznost i bezbedna voznja uz{" "}
          <span className="text-primary font-bold">Super Taxi</span>
        </p>
        {windowWidth > 768 ? (
          <AnchorLink
            href="#Kontakt"
            className="bg-light text-primary hover:bg-primary hover:text-light transition-colors duration-300 font-bold px-8 py-4 rounded-2xl w-max"
          >
            Poruči Vozilo
          </AnchorLink>
        ) : (
          <a
            href="tel:0643163000"
            className="bg-light text-primary hover:bg-primary hover:text-light transition-colors duration-300 font-bold px-8 py-4 rounded-2xl w-max"
          >
            Poruči Vozilo
          </a>
        )}

        {/* <div className="bg-light text-primary hover:bg-primary hover:text-light transition-colors duration-300 font-bold px-8 py-4 rounded-2xl w-max">
        Poruci Vozilo
      </div> */}
        <div className="absolute -left-[5%] top-[50%] rotate-[30deg] hidden xl:block w-[250px]">
          <Pin color={"var(--light)"} />
        </div>

        <div className="absolute -right-[15%] top-[30%] -rotate-12 hidden xl:block w-[500px]">
          <Pin color={"var(--primary)"} />
        </div>
      </div>
    </div>
  );
};

export default Header;
